import { call, put, select, takeLatest, delay, race } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import {
  PROJECT__CREATE_START,
  PROJECT__LOAD_BY_ID_START,
  PROJECT__LOAD_FACILITATOR_BY_PROJECT_START,
  PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_START,
  PROJECT__LOAD_RECOMMENDATIONS_START,
  PROJECT__LOAD_SELLER_BY_PROJECT_START,
  PROJECT__LOAD_START,
  PROJECT__LOAD_WORKTYPES_START,
  PROJECT__UPDATE_LAST_VISIT,
  PROJECT__UPDATE_NAME_START,
} from '../actions'
import { closeOverlay } from '../actions/overlay'
import {
  changePage,
  createProjectError,
  createProjectSuccess,
  loadFacilitatorByProjectError,
  loadFacilitatorByProjectSuccess,
  loadFullConfirmationTokenError,
  loadFullConfirmationTokenSuccess,
  loadProjectByIdError,
  loadProjectByIdSuccess,
  loadProjectsError,
  loadProjectsSuccess,
  loadRecommendationsError,
  loadRecommendationsSuccess,
  loadSellerByProjectError,
  loadSellerByProjectSuccess,
  loadWorktypeError,
  loadWorktypeSuccess,
  updateLastVisit,
  updateNameError,
  updateNameSuccess,
} from '../actions/project'
import { logError } from '../actions/system'
import { allProjectsSelector, isAlgarDeskSelector, isBtoBSelector, projectPaginationParamsSelector, userProfileSelector } from '../selectors'
import { isMobile } from '../utils'
import { getCodeFromError } from '../utils/error'
import PMDC from '../utils/pmdc'
import { MY_FOLDERS_ROUTE } from '../constants/router'

function* loadProjectById() {
  const retryDelay = 1000; // Intervalle de réessai de 1 seconde
  const maxTime = 10000; // Temps maximum total de 5 secondes
  let elapsedTime = 0; // Temps écoulé

  try {
    const { user, project } = yield select();

    while (elapsedTime < maxTime) {
      console.log("Tentative recuperation projet");
      const startTime = Date.now();


      const { response, timeout } = yield race({
        response: call(
            PMDC(user.accessToken, user.decodedAccessToken).getProjectById,
            project.selectedProjectId
        ),
        timeout: delay(retryDelay)
      });

      if (response) {
        yield put(loadProjectByIdSuccess(response.data));
        yield put(updateLastVisit(response.data.id));
        return;
      }

      elapsedTime += Date.now() - startTime;
    }

  } catch (err) {
    yield put(
        loadProjectByIdError({
          code: getCodeFromError(err),
          message: 'Erreur lors du chargement du projet après plusieurs tentatives',
        })
    );
    yield put(logError(err));
  }
}

function* loadProjects(action) {
  const retryDelay = 1000; // Intervalle de réessai de 1 seconde
  const maxTime = 10000; // Temps maximum total de 10 secondes
  let elapsedTime = 0; // Temps écoulé

  try {
    const { user } = yield select();

    while (elapsedTime < maxTime) {
      const startTime = Date.now();


      const { response, timeout } = yield race({
        response: call(
            PMDC(user.accessToken, user.decodedAccessToken)
                .getProjectsByUserSortedByLast,
            user.profile.id,
            action.payload
        ),
        timeout: delay(retryDelay)
      });

      if (response) {
        yield put(loadProjectsSuccess(response.data));
        return;
      }

      elapsedTime += Date.now() - startTime;
    }

  } catch (err) {
    yield put(
        loadProjectsError({
          code: getCodeFromError(err),
          message: 'Erreur lors du chargement des projets après plusieurs tentatives',
        })
    );
    yield put(logError(err));
  }
}

function* loadWorktypes() {
  try {
    const { user } = yield select()
    const { data } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken).getWorkTypesWithoutPrice
    )
    yield put(loadWorktypeSuccess(data))
  } catch (err) {
    yield put(
      loadWorktypeError({
        code: getCodeFromError(err),
        message: 'Erreur lors du chargement des Worktypes',
      })
    )
    yield put(logError(err))
  }
}

function* loadFacilitatorByProject() {
  try {
    const { user, project } = yield select()
    const { data } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken).getFacilitatorByProject,
      project.facilitatorToLoadProjectId
    )
    yield put(loadFacilitatorByProjectSuccess(data))
  } catch (err) {
    yield put(
      loadSellerByProjectError({
        code: getCodeFromError(err),
        message: 'Erreur lors du chargement du contact',
      })
    )
    yield put(logError(err))
  }
}

function* loadSellerByProject() {
  try {
    const { user, project } = yield select()
    const { data } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken).getSellerByProject,
      project.sellerToLoadProjectId
    )
    yield put(loadSellerByProjectSuccess(data))
  } catch (err) {
    yield put(
      loadFacilitatorByProjectError({
        code: getCodeFromError(err),
        message: 'Erreur lors du chargement du facilitateur',
      })
    )
    yield put(logError(err))
  }
}

function* loadFullConfirmationToken() {
  try {
    const { user } = yield select()
    const { data } = yield call(
      PMDC(user.accessToken).getConfirmationTokenByValue,
      user.confirmationToken
    )
    yield put(loadFullConfirmationTokenSuccess(data))
  } catch (err) {
    yield put(
      loadFullConfirmationTokenError({
        code: getCodeFromError(err),
        message: 'Erreur lors de la confirmation du compte',
      })
    )
    yield put(logError(err))
  }
}

function* updateProjectLastVisit(action) {
  try {
    const { user } = yield select()
    yield call(
      PMDC(user.accessToken, user.decodedAccessToken).updateLastVisit,
      action.payload
    )
  } catch (err) {
    yield put(logError(err))
  }
}

function* loadRecommendations() {
  try {
    const { user, project } = yield select()
    const { data } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken).getRecommendations,
      project.selectedProjectId
    )
    yield put(loadRecommendationsSuccess(data))
  } catch (err) {
    yield put(
      loadRecommendationsError({
        code: getCodeFromError(err),
        message: 'Erreur lors du chargement des recommendations',
      })
    )
    yield put(logError(err))
  }
}

function* updateProjectName(action) {
  try {
    const { user } = yield select()
    const { project, newProjectName } = action.payload
    const { dealId } = project.dealAssociation
    const { data } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken).updateProjectName,
      dealId,
      newProjectName
    )

    if (!data.data.properties.projectname) {
      yield put(
        updateNameError({
          code: getCodeFromError(err),
          message: `Le props 'projectname' n'existe pas encore dans hubspot`,
        })
      )
      yield put(logError(err))
    }

    if (data.status === 200) {
      yield put(
        updateNameSuccess(project, data.data.properties.projectname.value)
      )
    } else {
      yield put(
        updateNameError({
          code: getCodeFromError(err),
          message: 'Erreur lors de la modification du nom du projet',
        })
      )
      yield put(logError(err))
    }
  } catch (err) {
    yield put(
      updateNameError({
        code: getCodeFromError(err),
        message: 'Erreur lors de la modification du nom du projet',
      })
    )
    yield put(logError(err))
  }
}

function* createProject(action) {
  try {
    const { user } = yield select()

    const isBtoB = yield select(isBtoBSelector)
    const isAlgarDesk = yield select(isAlgarDeskSelector)
    const profile = yield select(userProfileSelector)
    const projects = yield select(allProjectsSelector)

    const { firstName, lastName } = profile
    const defaultProjectName = `Dossier ${projects.total + 1} ${firstName} ${lastName}`

    const payload = {
      address: action.payload.address,
      projectTypesCodes: action.payload.projectTypesCodes,
      typeClient: action.payload.typeClient,
      projectname: action.payload.projectname || defaultProjectName,
      isAlgarDesk: isAlgarDesk,
    }

    // Create project
    const { data } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken).createProject,
      user.profile.id,
      payload
    )

    // Update deal stage if btob
    if (isBtoB || isAlgarDesk) {
      yield call(
        PMDC(user.accessToken, user.decodedAccessToken).updateDealStage,
        data.dealAssociation.dealId,
        window.processRuntime.env.HUBSPOT_FORM_WAITING_STAGE_ID
      )
    }

    const paginationParams = yield select(projectPaginationParamsSelector)
    const { queryString } = paginationParams

    const { data: dataRefetched } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken)
        .getProjectsByUserSortedByLast,
      user.profile.id,
      {
        ...paginationParams,
        offset: 0,
        queryString,
      }
    )
    yield put(createProjectSuccess(data))
    yield put(loadProjectsSuccess(dataRefetched))
    yield put(closeOverlay())

    // In mobile return to switch page for btob
    if (isBtoB) {
      if (isMobile) {
        yield put(changePage('switch'))
      } else {
        yield put(push(MY_FOLDERS_ROUTE))
      }
    }
  } catch (err) {
    yield put(
      createProjectError({
        code: getCodeFromError(err),
        message: 'Erreur lors de la création du projet',
      })
    )
    yield put(logError(err))
  }
}

export default [
  takeLatest(PROJECT__LOAD_START, loadProjects),
  takeLatest(PROJECT__LOAD_WORKTYPES_START, loadWorktypes),
  takeLatest(PROJECT__LOAD_BY_ID_START, loadProjectById),
  takeLatest(
    PROJECT__LOAD_FACILITATOR_BY_PROJECT_START,
    loadFacilitatorByProject
  ),
  takeLatest(PROJECT__LOAD_SELLER_BY_PROJECT_START, loadSellerByProject),
  takeLatest(
    PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_START,
    loadFullConfirmationToken
  ),
  takeLatest(PROJECT__UPDATE_LAST_VISIT, updateProjectLastVisit),
  takeLatest(PROJECT__LOAD_RECOMMENDATIONS_START, loadRecommendations),
  takeLatest(PROJECT__UPDATE_NAME_START, updateProjectName),
  takeLatest(PROJECT__CREATE_START, createProject),
]
