import {faExternalLink} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {APIACTIONS, linkTypes} from '../../constants'
import {
    isTerrealCustomerSelector,
    projectSelector,
    recommendationsSelector,
    selectedProjectSelector
} from '../../selectors'
import {
    ActionItemLink,
    ActionItemLinkDot,
    ActionItemLinksStyled,
    ActionItemLinkTargetIcon,
    ActionItemLinkText
} from './styled'
import {setAccountActivationOverlay, setIframeOverlay} from '../../actions/overlay'
import {startUpdateActionLinkStatus} from '../../actions/action'
import {useHistory} from 'react-router-dom'
import {DEMO_ROUTE} from '../../constants/router'

const GETIR_FORM_URL_VERSIONS = {
    printing: '1',
    caseSent: '2',
    caseReceived: '3',
    caseUnderInvestigation: 'compl1',
    analyzingMayorRequest: 'compl2'
}

const getLinkText = (action, link) => {
    switch (action.metaCode) {
        case APIACTIONS.FORM_ON_BOARDING:
        case APIACTIONS.FORM_ON_BOARDING_DESK: {
            if (action.links.length === 3 && link.subType) {
                return `Compléter la partie ${link.subType.split('-')[1]}`
            }
            return 'Compléter le formulaire'
        }

        // case APIACTIONS.FORM_ON_BOARDING_DESK:
        //     return 'Déposer les éléments de mon dossier'

        case APIACTIONS.MANDATE_SIGN:
            return 'Signer le mandat de gestion'

        case APIACTIONS.CASE_V1_FEEDBACK:
        case APIACTIONS.CASE_V2_FEEDBACK:
        case APIACTIONS.CASE_V3_FEEDBACK:
        case APIACTIONS.MAYOR_REQUEST_V1_FEEDBACK:
        case APIACTIONS.MAYOR_REQUEST_V2_FEEDBACK:
        case APIACTIONS.ADVICE_CALL_MAYOR_REQUEST:
        case APIACTIONS.ADVICE_CALL_MAYOR_REQUEST_BTOB:
        case APIACTIONS.REFUSAL_CALL:
            return 'Indiquer mes retours'

        case APIACTIONS.CASE_RECEIVED:
        case APIACTIONS.CASE_UNDER_INVESTIGATION:
        case APIACTIONS.MAYOR_REQUEST_RECEIVED:
            return 'Déposer un document reçu'

        case APIACTIONS.MAYOR_REQUEST_UPLOAD:
        case APIACTIONS.MAYOR_REQUEST_UPLOAD_DESK:
            return 'Déposer les pièces complémentaires'

        case APIACTIONS.PANEL_RECEIVED:
            return "Confirmer l'installation du panneau"

        case APIACTIONS.WAITING_PAYMENT:
            return link.text

        default:
            return 'Compléter le formulaire'
    }
}

const getGetirFormUrlVersion = (actionMetaCode) => {
    return GETIR_FORM_URL_VERSIONS[actionMetaCode] ?? null
}

const getLinkUrl = (action, link, recommendations, recommandationsRefus, nomClientBtob) => {
    const isTerrealCustomer = useSelector(isTerrealCustomerSelector)
    const { terrealData } = useSelector(selectedProjectSelector)
    let worktypes = ''

    if (action.workTypes && action.workTypes.length > 0) {
        worktypes = action.workTypes.map((i) => i.typeformCode).join(',')
    }

    const typeformDomain = window.processRuntime.env.TYPEFORM_DOMAIN
    // const selfURL = window.processRuntime.env.SELF_URL

    // let baseURL = `${link.typeformDomain}/${link.typeformDomain === typeformDomain ? 'to/' : ''
    //   }`

    // TODO : Form title (tally) ?
    // const defaultQuery = `action_id=${action._id}&deal_id=${link.dealId}`

    // let url = `${selfURL}/formulaire/${action.projectId}/${link.typeformId}?${defaultQuery}`
    let url = `${link.typeformDomain}/${link.typeformDomain === typeformDomain ? 'to/' : ''}${link.typeformId}?action_id=${action._id}&deal_id=${link.dealId}`

    // if (action.metaCode === APIACTIONS.FORM_ON_BOARDING) {
    //   url = `${baseURL}${link.typeformId}?${defaultQuery}`
    // }

    if (worktypes) url = `${url}&worktypes=${worktypes}`
    if (link.dealstageId) url = `${url}&dealstage_id=${link.dealstageId}`

    //installateur_terreal
    if (isTerrealCustomer && link.typeformId === 'n9DXeG') {
        const { adress, declarer, kit } = terrealData
        url = `${url}&worktypes=${worktypes}&adressstreet=${adress.street}&adresspostalcode=${adress.postalCode}&adressadditionnalInfo=${adress.additionnalInfo}&adresscity=${adress.city}&declarerfirstname=${declarer.firstname}&declarerlastname=${declarer.lastname}&declareremail=${declarer.email}&declareradress=${declarer.adress}&kitnumber=${kit.number}&kitpowerwatts=${kit.powerWatts}`
    }

    switch (action.metaCode) {
        case APIACTIONS.CASE_V1_FEEDBACK:
            return `${url}&recommandations_v1=${recommendations.feedbackV1}`

        case APIACTIONS.CASE_V2_FEEDBACK:
            return `${url}&cerfa_v2=${recommendations.feedbackV2}`

        case APIACTIONS.FORM_ON_BOARDING_BTOB: {
            const version = getGetirFormUrlVersion(action.metaCode)

            if (version) {
                url = `${url}&version_number=${version}`
            }

            if (nomClientBtob) {
                url = `${url}&company_name=${nomClientBtob}`
            }

            return url
        }

        case APIACTIONS.MANDATE_SIGN: {
            const signatureLink = action.links[0].signatureLink
            return `${signatureLink}`
        }

        case APIACTIONS.WAITING_PAYMENT: {
            if (link.type === linkTypes.BACK_TO_QUOTE) {
                return `${window.processRuntime.env.TUNNEL_URL}/devis/${action.projectId}`
            }
            return `${window.processRuntime.env.TUNNEL_URL}/projet/${action.projectId}`
        }

        case APIACTIONS.REFUSAL_CALL:
            return `${url}&recommandations_refus=${recommandationsRefus}`

        default:
            return url
    }
}

const ActionItemLinks = ({ action: _action }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const isDemoProject = history.location.pathname === DEMO_ROUTE

    const recommendations = useSelector(recommendationsSelector)
    const { workTypesIds } = useSelector(selectedProjectSelector)
    const { workTypes } = useSelector(projectSelector)
    const projectWorkTypes = Object.values(workTypes).filter((wt) =>
        workTypesIds.includes(wt.id)
    )
    const { nomClientBtob, phoneCallDone, refusRecosA } = useSelector(
        selectedProjectSelector
    )

    const action = { ..._action, workTypes: projectWorkTypes }

    const isDoneAction = action.status === 'done'

    const handleUpdateLinkStatus = (link) => {
        if (!link.status || link.status === 'unread') {
            dispatch(startUpdateActionLinkStatus(action._id, link._id, 'read'))
        }
    }

    const handleIframeOverlay = (signLink) => {
        dispatch(setIframeOverlay(signLink))
    }

    return (
        <ActionItemLinksStyled>
            {action.links && action.links.length > 0 && action.links.map((link, index) => {
                // TODO (eric) : link status, read, in progress ?
                const isDone = action.status === 'done' || link.status === 'done'
                const linkText = getLinkText(action, link)
                const linkUrl = link.url || getLinkUrl(action, link, recommendations, refusRecosA, nomClientBtob)
                const isMandateLink = link.type === linkTypes.SIGNATURE_PROCEDURE
                const isQuoteLink = link.type === linkTypes.BACK_TO_QUOTE
                const disabledQuoteLink = isQuoteLink && !phoneCallDone

                return (
                    <ActionItemLink key={index} isDone={isDone}>
                        <ActionItemLinkDot isDone={isDone} disabled={disabledQuoteLink} />
                        <ActionItemLinkText
                            href={disabledQuoteLink || isDoneAction || isDone ? undefined : linkUrl}
                            target={"_blank"}
                            isDone={isDone}
                            onClick={(e) => {
                                if (isDemoProject) {
                                    e.preventDefault()
                                    dispatch(setAccountActivationOverlay())
                                }
                                // else {
                                //     if (disabledQuoteLink || isDoneAction) return
                                    // if (isMandateLink) {
                                        // e.preventDefault()
                                        // handleIframeOverlay(linkUrl)
                                    // }
                                // }
                            }}
                            disabled={disabledQuoteLink}
                        >
                            {linkText}
                        </ActionItemLinkText>
                        {!isMandateLink && (
                            <ActionItemLinkTargetIcon
                                isDone={isDone}
                                disabled={disabledQuoteLink}
                            >
                                <FontAwesomeIcon icon={faExternalLink} size="2x" />
                            </ActionItemLinkTargetIcon>
                        )}
                    </ActionItemLink>
                )
            })
            }
        </ActionItemLinksStyled>
    )
}

ActionItemLinks.propTypes = {
    action: PropTypes.object.isRequired
}

export default ActionItemLinks

